export default [
    {
        header: 'Sipariş',
        icon: 'ShoppingCartIcon',
        children: [
            {
                title: 'Siparişler',
                route: 'siparis',
                icon: 'ShoppingCartIcon',
            },
            {
                title: 'Fatura Sepeti',
                route: 'siparis-faturasepeti',
                icon: 'ShoppingCartIcon',
            },
            {
                title: 'Hazırlanıyor',
                route: 'siparis-hazirlaniyor',
                icon: 'SearchIcon',
            },
            {
                title: 'Toplanacaklar',
                route: 'siparis-toplanacak',
                icon: 'DatabaseIcon',
            },
        ],
    }
]
