export default [
    {
        title: 'Trendyol',
        icon: 'ShoppingBagIcon',
        children: [
            {
                title: 'Ürünler',
                route: 'trendyol',
                icon: 'ShoppingCartIcon',
            },
            {
                title: 'Ürün Açma',
                icon: 'BoxIcon',
                route: 'trendyol-urunacma',
            },
            {
                title: 'Eşleştirme',
                icon: 'BoxIcon',
                route: 'trendyol-eslestirme',
            },
        ],
    }
]
