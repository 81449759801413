export default [
    {
        header: 'Ürünler',
        icon: 'PackageIcon',
        children: [
            {
                title: 'Ürün',
                route: 'product',
                icon: 'PackageIcon',
            },
            {
                title: 'Ürünler',
                route: 'products',
                icon: 'PackageIcon',
            },
            {
                title: 'Sabit Fiyat',
                route: 'product2',
                icon: 'PackageIcon',
            },
            {
                title: 'Takım',
                route: 'takim',
                icon: 'PackageIcon',
            },
            {
                title: 'Markalar',
                route: 'marka',
                icon: 'BriefcaseIcon'
            },
            {
                title: 'Kategoriler',
                route: 'category',
                icon: 'SmartphoneIcon',
            },
            {
                title: 'Yayımevleri',
                route: 'publisher',
                icon: 'BookOpenIcon',
            },
            {
                title: 'Yazarlar',
                route: 'product-author',
                icon: 'UsersIcon',
            },
            {
                title: 'test',
                route: 'test',
                icon: 'UsersIcon',
            },
        ],
    }
]
