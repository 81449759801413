import home from "@/navigation/horizontal/home";
import product from "@/navigation/horizontal/product";
import order from "@/navigation/horizontal/order";
import supplier from "@/navigation/horizontal/supplier";
import marketplace from "@/navigation/horizontal/marketplace";

import stock from "@/navigation/horizontal/stock";
import invoice from "@/navigation/horizontal/invoice";
import Question from "@/navigation/horizontal/question";

export default [
    ...home,
    ...product,
    ...order,
    ...Question,
    ...supplier,
    ...marketplace,
    ...stock,
    ...invoice
]
