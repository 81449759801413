export default [{
    title: 'Amazon',
    icon: 'ShoppingBagIcon',
    children: [
        {
            title: 'Mağaza Ürünleri',
            icon: 'BoxIcon',
            route: 'amazon-store-product',
        },
        {
            title: 'Ön Sipariş',
            icon: 'BoxIcon',
            route: 'amazon-pre-order',
        },
        {
            title: 'Eşleştirme',
            icon: 'BoxIcon',
            route: 'amazon-eslestirme',
        },
        {
            title: 'Pasif Ürünler',
            icon: 'BoxIcon',
            route: 'amazon-pasifurun',
        },
    ],
}]
