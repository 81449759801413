export default [
    {
        header: 'Tedarikçiler',
        icon: 'TruckIcon',
        children: [
            {
                title: 'Tedarikçiler',
                route: 'supplier',
                icon: 'TruckIcon',
            },
            {
                title: 'Tedarikçi Ürünleri',
                route: 'supplier-product',
                icon: 'BoxIcon',
            },
            {
                title: 'Kategoriler',
                route: 'supplier-category',
                icon: 'BriefcaseIcon',
            },
            {
                title: 'Yayımevleri',
                route: 'supplier-publish',
                icon: 'BookOpenIcon',
            },
            {
                title: 'Yazarlar',
                route: 'supplier-author',
                icon: 'UsersIcon',
            },
        ],
    }
]
