<template>
  <li class="nav-item">
    <b-form-input placeholder="Takip No" type="text" size="sm" @keyup.enter="setTrackingNumber"/>
  </li>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import soundMixins from "@/mixins/soundMixins";

export default {
  name: "AgreementSearch.vue",
  mixins: [soundMixins],
  methods: {
    setTrackingNumber($event) {
      let number = $event.target.value;
      $event.target.value = "";
      this.$store.dispatch('agreementDetail/setTrackingNumber', {search_tracking_number: number})
          .then(res => {
            this.autoPlay(res.variant)
            this.$toast({
              component: ToastificationContent,
              props: res
            })
          })
    }
  }
}
</script>

<style scoped>

</style>
