export default [
    {
        title: 'Hepsiburada',
        icon: 'ShoppingBagIcon',
        children: [
            {
                title: 'Ürünler',
                icon: 'BoxIcon',
                route: 'hepsiburada-product',
            },
            {
                title: 'Kampanya',
                icon: 'BoxIcon',
                route: 'hepsiburada-campaign',
            },
            {
                title: 'Eşleştirme',
                icon: 'BoxIcon',
                route: 'hepsiburada-eslestirme',
            },
        ],
    }
]
