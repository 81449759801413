export default [
    {
        header: 'Sorular',
        icon: 'ShoppingCartIcon',
        children: [
            {
                title: 'Sorular',
                route: 'question',
                icon: 'ShoppingCartIcon',
            },
            {
                title: 'Hazır Cevaplar',
                route: 'question-answer',
                icon: 'ShoppingCartIcon',
            },
        ],
    }
]
