import hepsiburada from "@/navigation/horizontal/hepsiburada";
import trendyol from "@/navigation/horizontal/trendyol";
import amazon from "@/navigation/horizontal/amazon";
export default [
    {
        header: 'PazarYerileri',
        icon: 'ShoppingBagIcon',
        children: [
            {
                title: 'Pazaryerleri',
                icon: 'ShoppingBagIcon',
                route: 'store',
            },
            ...hepsiburada,
            ...trendyol,
            ...amazon
        ],
    }
]
