export default [
    {
        header: 'Fatura',
        icon: 'ShoppingCartIcon',
        children: [
            {
                title: 'Faturalar',
                route: 'fatura-gelen',
                icon: 'ShoppingCartIcon',
            },
            {
                title: 'İstatistik',
                route: 'fatura-istatistik',
                icon: 'ShoppingCartIcon',
            },
        ]
    }
]
