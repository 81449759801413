/* eslint-disable */
// prettier-ignore
export default {
    pages: {
        key: 'title',
        data: [
            {
                title: 'Mutabakat Takip No Girişi',
                route: {name: 'agreement-detail'},
                icon: 'TruckIcon',
                isBookmarked: true
            },
            {
                title: 'Mutabakatlar',
                route: {name: 'agreement'},
                icon: 'AwardIcon',
                isBookmarked: true
            }
        ],
    },
    files: {
        key: 'file_name',
        data: [],
    },
    contacts: {
        key: 'name',
        data: [],
    },
}
/* eslint-enable */
