<template>
  <b-nav-item-dropdown
      class="dropdown-notification mr-25"
      menu-class="dropdown-menu"
      right
  >
    <template #button-content>
      <feather-icon
          v-if="isError"
          badge-classes="bg-danger"
          badge="Hata"
          class="text-body"
          icon="XCircleIcon"
          size="21"
      />
      <feather-icon
          v-else
          :badge="_orderQuestionsCount"
          badge-classes="bg-danger"
          class="text-body"
          icon="MailIcon"
          size="21"
      />
    </template>
    <li class="dropdown-menu-item" v-for="(orderQuestion,index) in _orderQuestions" :key="index">
      <div class="dropdown-header d-flex">
        <b-badge v-if="!orderQuestion.status" variant="danger">{{ orderQuestion.message }}</b-badge>
        <a href="https://partner.trendyol.com/questions/orders" target="_blank"
           class="notification-title mb-0 mr-auto"> {{ orderQuestion.store.store_name }} </a>
        <b-badge pill variant="light-primary">
          {{ orderQuestion.count }}
        </b-badge>

      </div>
    </li>

  </b-nav-item-dropdown>
</template>

<script>
import {mapGetters} from "vuex";
import {integer} from "@core/utils/validations/validations";

export default {
  name: "Questions",
  computed: {
    ...mapGetters("orderQuestion", ['_orderQuestions', '_orderQuestionsCount', 'isError']),
  },
}
</script>

<style scoped>
.red-icon {
  color: red;
}
</style>