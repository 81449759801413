<template>
  <li class="nav-item">
    <feather-icon
        :badge="statusCount.total"
        badge-classes="bg-danger"
        class="text-body"
        icon="BriefcaseIcon"
        size="21"
        v-b-tooltip.hover.v-warning
        :title="order_status(statusCount.order_status_id) +' Sipariş Adedi'"
    />&nbsp;
  </li>
</template>

<script>
import OrderStatusMixins from "@/mixins/orderStatusMixins";

export default {
  name: "OrderStatusCounts",
  mixins: [OrderStatusMixins],
  props: {
    statusCount: {type: Object, required: true}
  }
}
</script>

<style scoped>

</style>
