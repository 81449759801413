<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21"/>
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <bookmarks/>
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <!--<dark-Toggler class="d-none d-lg-block"/>-->
      <!--<search-bar/>-->
      <OrderStatusCounts
          v-for="(statusCount,index) in _orderStatusCounts"
          :key="index"
          :status-count="statusCount"
      />
      <AgreementSearch/>
      &nbsp;<Questions></Questions>
      <!--<notification-dropdown/>-->
      <user-dropdown/>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import DarkToggler from './components/DarkToggler.vue'
import SearchBar from './components/SearchBar.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import AgreementSearch from "@core/layouts/components/app-navbar/components/AgreementSearch";
import OrderStatusCounts from "@core/layouts/components/app-navbar/components/OrderStatusCounts";
import {mapGetters} from "vuex";
import Questions from "@core/layouts/components/app-navbar/components/Questions";

export default {
  components: {
    BLink,
    BNavbarNav,
    Bookmarks,
    DarkToggler,
    SearchBar,
    NotificationDropdown,
    UserDropdown,
    AgreementSearch,
    OrderStatusCounts,
    Questions
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  computed: {
    ...mapGetters("order", ["_orderStatusCounts"]),
  },
  created() {
    this.$store.dispatch('order/getOrderStatusCounts');
    setInterval(() => {
      this.$store.dispatch('order/getOrderStatusCounts');
    }, 60000)
  }
}
</script>
