export default [{
    header: 'Stok/İade',
    icon: 'ShoppingBagIcon',
    children: [
        {
            title: 'Ürünler v2',
            icon: 'BoxIcon',
            route: 'v2-stock',
        },
        {
            title: 'Stok Girişi',
            icon: 'BoxIcon',
            route: 'stock-entry',
        },
        {
            title: 'Stok Kontrol',
            icon: 'BoxIcon',
            route: 'stock-comfirm',
        },
        {
            title: 'Sayım Girişi',
            icon: 'BoxIcon',
            route: 'stok-taking',
        },
    ],
}]
